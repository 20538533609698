
import * as Sentry from '@sentry/browser';



Sentry.init({
  dsn: "https://2167231570d74901b73bddb0cebf0be8@sentry.io/6536",
  
  release: '9818ad15121a857846596f568fa7a1ea2b9c9d05',
  
  linesOfContext: 500, 
  autoSessionTracking: false,
  environment: 'production',
  ignoreErrors: [ 'DOMException: WRONG_DOCUMENT_ERR', 'Non-Error promise rejection captured', 'EvalError' ]
});


window.Sentry = Sentry

